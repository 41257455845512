import React, { useState } from 'react';
import './App.css';

function App() {
  const [jobRoles, setJobRoles] = useState('');
  const [industries, setIndustries] = useState('');
  const [location, setLocation] = useState('');
  const [results, setResults] = useState([]);
  const [loading, setLoading] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    const jobRolesArray = jobRoles.split(',').map(role => role.trim());
    const industriesArray = industries.split(',').map(ind => ind.trim());

    const response = await fetch('http://ec2-34-229-167-160.compute-1.amazonaws.com:5000/scrape', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        job_roles: jobRolesArray,
        industries: industriesArray,
        location,
      }),
    });

    const data = await response.json();
    setResults(data);
    setLoading(false);
  };

  const renderArrayInput = (input) => {
    const items = input.split(',').map(item => item.trim());
    return (
      <div className="array-display">
        {items.map((item, index) => (
          <span key={index} className="array-chip">{item}</span>
        ))}
      </div>
    );
  };

  return (
    <div className="App">
      <h1>indeed job Scraper</h1>
      <form onSubmit={handleSubmit}>
        <textarea
          placeholder="Job Roles (comma-separated)"
          value={jobRoles}
          onChange={(e) => setJobRoles(e.target.value)}
        ></textarea>
        <div className="array-input-label">
          <h3>Job Roles</h3>
          {jobRoles && renderArrayInput(jobRoles)}
        </div>

        <textarea
          placeholder="Industries (comma-separated)"
          value={industries}
          onChange={(e) => setIndustries(e.target.value)}
        ></textarea>
        <div className="array-input-label">
          <h3>Industries</h3>
          {industries && renderArrayInput(industries)}
        </div>

        <input
          type="text"
          placeholder="Location"
          value={location}
          onChange={(e) => setLocation(e.target.value)}
        />
        <button type="submit">Scrape</button>
      </form>

      {loading && (
        <div className="loader">
          <div className="spinner"></div>
          <p>Loading...</p>
        </div>
      )}

      <div>
        {results.length > 0 && (
          <table border="1">
            <thead>
              <tr>
                <th>Job Role</th>
                <th>Industry</th>
                <th>Company</th>
                <th>Location</th>
                <th>Job Title</th>
                <th>Date</th>
                <th>Company Size</th>
              </tr>
            </thead>
            <tbody>
              {results.map((result, index) => (
                <tr key={index}>
                  <td>{result.jobRole}</td>
                  <td>{result.industry}</td>
                  <td>{result.company}</td>
                  <td>{result.formattedLocation}</td>
                  <td>{result.displayTitle}</td>
                  <td>{new Date(result.date).toLocaleDateString()}</td>
                  <td>{result.companySize}</td>
                </tr>
              ))}
            </tbody>
          </table>
        )}
      </div>
    </div>
  );
}

export default App;
